import { $t } from "@/plugins/i18n";
const { VITE_HIDE_HOME } = import.meta.env;
const Layout = () => import("@/layout/index.vue");
import workbench from "@/assets/svg/workbench.svg";
import { getRank } from '../rank'
let rank = getRank('workbench')
export default {
  path: "/",
  name: "workbench",
  component: Layout,
  redirect: "/workbench/index",
  meta: {
    icon: workbench,
    // title: $t("menus.hshome"),
    title: "工作台",
    rank
    // showLink: false
  },
  children: [
    {
      path: "/workbench/index",
      name: "workbench",
      component: () => import("@/views/workbench/index.vue"),
      meta: {
        title: "工作台",
        keepAlive: false
      }
    }
  ]
} as RouteConfigsTable;
// export default {
//   path: "/",
//   name: "Home",
//   component: Layout,
//   redirect: "/welcome",
//   meta: {
//     icon: "homeFilled",
//     title: $t("menus.hshome"),
//     rank: 0,
//     // showLink: false
//   },
//   children: [
//     {
//       path: "/welcome",
//       name: "Welcome",
//       component: () => import("@/views/welcome/index.vue"),
//       meta: {
//         title: $t("menus.hshome"),
//         showLink: false
//       }
//     }
//   ]
// } as RouteConfigsTable;
