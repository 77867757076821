import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CustomParamsSerializer
} from "axios";
import {
  PureHttpError,
  RequestMethods,
  PureHttpResponse,
  PureHttpRequestConfig
} from "./types.d";
import { stringify } from "qs";
import NProgress from "../progress";
import { getToken, formatToken } from "@/utils/auth";
import { useUserStoreHook } from "@/store/modules/user";
import { ElMessage } from "element-plus";
import { fa } from "element-plus/es/locale";
import { router, resetRouter } from "@/router";
import { removeToken } from "@/utils/auth";
import { ElLoading } from "element-plus";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultConfig: AxiosRequestConfig = {
  // 请求超时时间
  timeout: 10000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  },
  // 数组格式参数序列化（https://github.com/axios/axios/issues/5142）
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer
  }
};

import { storageLocal, storageSession } from "@pureadmin/utils";
import { getConfig } from "@/config";
import { routerArrays } from "@/layout/types";
import { useAppStoreHook } from "@/store/modules/app";
import { useMultiTagsStoreHook } from "@/store/modules/multiTags";
function onReset() {
  removeToken();
  storageLocal().clear();
  storageSession().clear();
  const { Grey, Weak, MultiTagsCache, EpThemeColor, Layout } = getConfig();
  useAppStoreHook().setLayout(Layout);
  useMultiTagsStoreHook().multiTagsCacheChange(MultiTagsCache);
  router.push("/selectFactory");
  useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
  resetRouter();
}
class PureHttp {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  /** token过期后，暂存待执行的请求 */
  private static requests = [];

  /** 防止重复刷新token */
  private static isRefreshing = false;

  /** 初始化配置对象 */
  private static initConfig: PureHttpRequestConfig = {};

  /** 保存当前Axios实例对象 */
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  /** 重连原始请求 */
  private static retryOriginalRequest(config: PureHttpRequestConfig) {
    return new Promise(resolve => {
      PureHttp.requests.push((token: string) => {
        // config.headers["Authorization"] = formatToken(token);
        resolve(config);
      });
    });
  }

  /** 请求拦截 */
  private httpInterceptorsRequest(): void {
    PureHttp.axiosInstance.interceptors.request.use(
      async (config: PureHttpRequestConfig): Promise<any> => {
        // 开启进度条动画
        // NProgress.start();
        // 优先判断post/get等方法是否传入回调，否则执行初始化设置等回调
        if (typeof config.beforeRequestCallback === "function") {
          config.beforeRequestCallback(config);
          return config;
        }
        if (PureHttp.initConfig.beforeRequestCallback) {
          PureHttp.initConfig.beforeRequestCallback(config);
          return config;
        }
        /** 请求白名单，放置一些不需要token的接口（通过设置请求白名单，防止token过期后再请求造成的死循环问题） */
        const whiteList = ["/refreshToken", "/login"];
        return whiteList.some(v => config.url.indexOf(v) > -1)
          ? config
          : new Promise(resolve => {
            const data = getToken();
            if (data) {
              dayjs.extend(utc);
              dayjs.extend(timezone);
              const now = new Date().getTime();
              const expired = parseInt(data.expires) - now <= 0;
              if (expired) {
                if (!PureHttp.isRefreshing) {
                  PureHttp.isRefreshing = true;

                  // token过期刷新
                  useUserStoreHook()
                    .handRefreshToken({ refreshToken: data.refreshToken })
                    .then(res => {
                      const token = res.data.accessToken;
                      // config.headers["Authorization"] = formatToken(token);
                      config.headers["URI"] = window.location.href;
                      config.headers["from"] = "web";

                      config.headers["tz"] = dayjs.tz.guess();
                      PureHttp.requests.forEach(cb => cb(token));
                      PureHttp.requests = [];
                    })
                    .finally(() => {
                      PureHttp.isRefreshing = false;
                    });
                }
                resolve(PureHttp.retryOriginalRequest(config));
              } else {
                // config.headers["Authorization"] = formatToken(
                //   data.accessToken
                // );
                config.headers["URI"] = window.location.href;
                config.headers["from"] = "web";
                config.headers["tz"] = dayjs.tz.guess();
                resolve(config);
              }
            } else {
              resolve(config);
            }
          });
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  /** 响应拦截 */
  private httpInterceptorsResponse(): void {
    const instance = PureHttp.axiosInstance;
    instance.interceptors.response.use(
      (response: PureHttpResponse) => {
        const $config = response.config;
        // 关闭进度条动画
        NProgress.done();
        // 优先判断post/get等方法是否传入回调，否则执行初始化设置等回调
        if (typeof $config.beforeResponseCallback === "function") {
          $config.beforeResponseCallback(response);
          return response.data;
        }
        if (PureHttp.initConfig.beforeResponseCallback) {
          PureHttp.initConfig.beforeResponseCallback(response);
          return response.data;
        }
        return response.data;
      },
      (error: PureHttpError) => {
        const $error = error;
        $error.isCancelRequest = Axios.isCancel($error);
        // 关闭进度条动画
        NProgress.done();
        // 所有的响应异常 区分来源为取消请求/非取消请求
        return Promise.reject($error);
      }
    );
  }

  /** 通用请求工具函数 */
  public request<T>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: PureHttpRequestConfig,
    loading?: Boolean
  ): Promise<T> {
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as PureHttpRequestConfig;

    // 单独处理自定义请求/响应回调
    let res = {
      code: null,
      data: null,
      message: null,
      status: null,
      success: false
    };

    return new Promise((resolve, reject) => {
      let loadingInstance = null;
      if (loading) {
        loadingInstance = ElLoading.service({ fullscreen: true });
      }
      PureHttp.axiosInstance
        .request(config)
        .then((response: undefined) => {
          res = response;
          loadingInstance && loadingInstance.close();
          switch (res.code) {
            case 200:
              resolve(response);
              break;
            case 1043:
              ElMessage.error(res.message);
              if (process.env.NODE_ENV !== "development") {
                setTimeout(() => {
                  location.href = "/";
                }, 2000);
              }
              break;
            default:
              if (res.success) {
                resolve(response);
                return;
              }
              ElMessage.error(res.message);
          }
        })
        .catch(error => {
          loadingInstance && loadingInstance.close();
          res = error.response;
          if (res) {
            switch (res.status) {
              case 400:
                if (
                  res.data.message == "用户未选择工厂" ||
                  res.data.message == "没有工厂id"
                ) {
                  /** 清空缓存并返回登录页 */
                  onReset();
                } else {
                  ElMessage.error(res.data.message);
                }
                break;
              case 502:
                ElMessage.error(res.data.message);
                break;
              case 500:
                ElMessage.error(res.data.message);
                break;
              case 401:
                removeToken();
                router.push("/login");
                resetRouter();
                break;
              default:
                ElMessage.error(res.data.message);
            }
          } else {
            ElMessage.error('服务异常，请检查网络!');
          }
          resolve(null);
        });
    });
  }

  /** 单独抽离的post工具函数 */
  public post<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("post", url, params, config);
  }

  /** 单独抽离的get工具函数 */
  public get<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("get", url, params, config);
  }
}

export const http = new PureHttp();
