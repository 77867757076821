import { $t } from "@/plugins/i18n";
import syncManage from "@/assets/svg/developDesign.svg";
const Layout = () => import("@/layout/index.vue");
import { defineAsyncComponent } from "vue";
import { getRank } from '../rank'
let rank = getRank('developDesign')
export default {
  path: "/developDesign",
  redirect: "/developDesign/productPlanning",
  meta: {
    icon: syncManage,
    title: "开发设计",
    rank
  },
  children: [
    {
      path: "/developDesign/productPlanning",
      name: "ProductPlanning",
      component: () => import("@/views/developDesign/productPlanning.vue"),
      meta: {
        title: "新品企划",
        keepAlive: false,
        roles: ["order"]
      }
    },
    {
      path: "/developDesign/pattern",
      name: "Pattern",
      component: () => import("@/views/developDesign/pattern.vue"),
      meta: {
        title: "款式列表",
        keepAlive: false,
        roles: ["order"]
      }
    },
    {
      path: "/developDesign/review",
      name: "Review",
      component: () => import("@/views/developDesign/review.vue"),
      meta: {
        title: "审核",
        keepAlive: false,
        roles: ["order"]
      }
    },
    {
      path: "/developDesign/reviewDetail",
      name: "reviewDetail",
      component: defineAsyncComponent(() => import("@/views/developDesign/reviewDetail.vue")),
      meta: {
        title: "设计样板",
        keepAlive: false,
        showLink: false,
        roles: ["order"]
      }
    },
    {
      path: "/developDesign/statement",
      name: "DevelopDesignStatement",
      component: () => import("@/views/developDesign/statement/index.vue"),
      meta: {
        title: "报表",
        keepAlive: false,
        roles: ["order"]
      }
    }
  ]
};
