import sampleDressSvg from "@/assets/svg/sampleDress.svg";
import { getRank } from '../rank'
let rank = getRank('sampleDress')
export default {
  path: "/sampleDress",
  redirect: "/sampleDress/index",
  meta: {
    icon: sampleDressSvg,
    title: "样衣管理",
    rank
  },
  children: [
    {
      path: "/sampleDress/index",
      name: "sampleDress",
      component: () => import("@/views/sampleDress/index.vue"),
      meta: {
        title: "样衣库存",
        keepAlive: false
      }
    },
    {
      path: "/sampleDress/outDetail",
      name: "outDetail",
      component: () => import("@/views/sampleDress/outDetail/index.vue"),
      meta: {
        title: "出库明细",
        keepAlive: false
      }
    },
    {
      path: "/sampleDress/putInDetail",
      name: "putInDetail",
      component: () => import("@/views/sampleDress/putInDetail/index.vue"),
      meta: {
        title: "入库明细",
        keepAlive: false
      }
    },
  ]
} as RouteConfigsTable;
