import accessorySvg from "@/assets/svg/accessorie.svg";
import { getRank } from '../rank'
let rank = getRank('accessoryPurchase')
export default {
  path: "/accessoryPurchase",
  redirect: "/accessoryPurchase/inventory",
  meta: {
    icon: accessorySvg,
    title: "辅料进销存",
    rank
  },
  children: [
    {
      path: "/accessoryPurchase/accessoryManager",
      name: "accessoryManager",
      component: () => import("@/views/CRM/accessoryPurchase/accessoryManager/index.vue"),
      meta: {
        title: "辅料管理",
        keepAlive: false
      }
    },
    {
      path: "/accessoryPurchase/inventory",
      name: "accessoryInventory",
      component: () => import("@/views/CRM/accessoryPurchase/inventory/index.vue"),
      meta: {
        title: "仓库库存",
        keepAlive: false
      }
    },
    {
      path: "/accessoryPurchase/invoice",
      name: "AccessoryInvoice",
      component: () => import("@/views/CRM/accessoryPurchase/inAndOutStorge/index.vue"),
      meta: {
        title: "出入库单",
        keepAlive: false
      }
    },
    {
      path: "/accessoryPurchase/warning",
      name: "AccessoryWarning",
      component: () => import("@/views/CRM/accessoryPurchase/warning/index.vue"),
      meta: {
        title: "库存预警",
        keepAlive: false
      }
    },
    {
      path: "/accessoryPurchase/sell",
      name: "AccessorySell",
      component: () => import("@/views/CRM/accessoryPurchase/sell/index.vue"),
      meta: {
        title: "销售",
        keepAlive: false
      }
    },
    {
      path: "/accessoryPurchase/statement",
      name: "AccessoryStatement",
      component: () => import("@/views/CRM/accessoryPurchase/statement/index.vue"),
      meta: {
        title: "报表",
        keepAlive: false
      }
    },
  ]
} as RouteConfigsTable;
