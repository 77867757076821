import { defineStore } from 'pinia'
import axios from "axios";
const userSystemDataStore = defineStore('systemData', {
  state: () => ({
    data: {}
  }),
  getters: {},
  actions: {
    async setSystemData() {
      try {
        //获取系统的一些基础配置信息
        const resConstants = await axios.post("/main/common/constants");
        const resStaff = await axios.post("/main/staff/getStaffList", { is_duty: true, page_index: 1, page_size: 1500 });
        const resDepart = await axios.post("/main/department/getList");
        const resPartner = await axios.post("/main/partner/getList");
        let obj = {
          constants: resConstants.data.data,
          staffs: resStaff.data.data,
          departments: resDepart.data.data,
          partners: resPartner.data.data
        }
        // this.data = resConstants.data.data;
        this.data = obj;
      } catch (error) {
        console.log(error)
      }

    },
    getSystemData() {
      return this.data;
    },
  }
})
export default userSystemDataStore