import App from "./App.vue";
import router from "./router";
import { setupStore } from "@/store";
import ElementPlus from "element-plus";
import { useI18n } from "@/plugins/i18n";
import { getServerConfig } from "./config";
import { createApp, Directive } from "vue";
import { MotionPlugin } from "@vueuse/motion";
// import { useEcharts } from "@/plugins/echarts";
import { injectResponsiveStorage } from "@/utils/responsive";
// import * as Sentry from "@sentry/vue";
import { Plus } from "@element-plus/icons-vue";

// import Table from "@pureadmin/table";
// import PureDescriptions from "@pureadmin/descriptions";

// 引入重置样式
import "./style/reset.scss";
// 导入公共样式
import "./style/index.scss";
import "./style/mhg.scss";
import "./style/print.css";
import "./style/hiprint.css";
// 一定要在main.ts中导入tailwind.css，防止vite每次hmr都会请求src/style/index.scss整体css文件导致热更新慢的问题
import "./style/tailwind.css";
import "element-plus/dist/index.css";
import "./style/my-element-plus.scss"
// 导入字体图标
import "./assets/iconfont/iconfont.js";
import "./assets/iconfont/iconfont.css";
// 导入棉花果字体图标
import "./assets/mhgIconfont/iconfont.js";
import "./assets/mhgIconfont/iconfont.css";




import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// 自定义指令
import * as directives from "@/directives";
Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

// 全局注册`@iconify/vue`图标库
import {
  IconifyIconOffline,
  IconifyIconOnline,
  FontIcon
} from "./components/ReIcon";
app.component("IconifyIconOffline", IconifyIconOffline);
app.component("IconifyIconOnline", IconifyIconOnline);
app.component("FontIcon", FontIcon);
// import webPackage from '@mhg/web-package'
// 全局注册按钮级别权限组件
import { Auth } from "@/components/ReAuth";
//pinia
import { createPinia } from "pinia";
app.component("Auth", Auth);

//过滤器
import filter from "@/utils/filters";
import VueUeditorWrap from 'vue-ueditor-wrap';

//ant-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';


//socket
import socketio from "@/utils/socket.io";
try {
  const token = JSON.parse(window.localStorage.getItem("token"));
  app.use(socketio, {
    connection:
      process.env.NODE_ENV !== "development"
        ? location.origin
        : import.meta.env.VITE_API_URL,
    options: {
      auth: {
        token: token
      },
      query: {
        platform: "web"
      }
    }
  });
} catch (error) {

}




//  sentry
// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     app,
//     dsn: "https://2a87808a5f22b898296fe640c91b88be@sentry.58mhg.com/3"
//   });
// }
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
function useTable(app) {
  app.use(VXETable, {
  })
}

//input组件
// app.component('mhgFormInput', () => import('./components/mhgFormInput.vue'))
import { gCom } from "@/components/index";
import VueGridLayout from "vue-grid-layout";

import mhgTree from '@/components/mhgTree/index'

gCom(app);
//工具方法
import util from "@/utils/util";
getServerConfig(app).then(async config => {

  app.use(router);
  await router.isReady();
  injectResponsiveStorage(app, config);
  setupStore(app);
  app
    .use(MotionPlugin)
    .use(useI18n)
    .use(ElementPlus, { size: "default" })
    .use(createPinia)
    .use(filter)
    .use(util)
    .use(VueUeditorWrap)
    .use(Antd)
    .use(VueGridLayout)
    // .use(useEcharts);
    .use(useTable)
    .use(mhgTree)
  // .use(PureDescriptions);
  // .use(webPackage)
  app.mount("#app");
});