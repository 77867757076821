import warehouseSvg from "@/assets/svg/warehouse.svg";
import { getRank } from '../rank'
let rank = getRank('productPurchase')
export default {
  path: "/productPurchase",
  redirect: "/productPurchase/inventory",
  meta: {
    icon: warehouseSvg,
    title: "成品进销存",
    rank
  },
  children: [
    {
      path: "/productPurchase/inventory",
      name: "Inventory",
      component: () => import("@/views/CRM/productPurchase/inventory/index.vue"),
      meta: {
        title: "仓库库存",
        keepAlive: false
      }
    },
    {
      path: "/productPurchase/invoice",
      name: "Invoice",
      component: () => import("@/views/CRM/productPurchase/inAndOutStorge/index.vue"),
      meta: {
        title: "出入库单",
        keepAlive: false
      }
    },
    {
      path: "/productPurchase/warning",
      name: "Warning",
      component: () => import("@/views/CRM/productPurchase/warning/index.vue"),
      meta: {
        title: "库存预警",
        keepAlive: false
      }
    },
    {
      path: "/productPurchase/sell",
      name: "Sell",
      component: () => import("@/views/CRM/productPurchase/sell/index.vue"),
      meta: {
        title: "销售",
        keepAlive: false
      }
    },
    {
      path: "/productPurchase/statement",
      name: "Statement2",
      component: () => import("@/views/CRM/productPurchase/statement/index.vue"),
      meta: {
        title: "报表",
        keepAlive: false
      }
    },
  ]
} as RouteConfigsTable;
