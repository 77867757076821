#文本字段
wen_ben:
  ming_cheng: 名称
  ke_hu: 客户
  shi_jian: 时间
  huo_hao: 货号
  shang_ping: 款式
  zhi_chi: 支持
  ge_shi: 格式
  xuan_ze: 选择

  shang_ping_ming_cheng: 款式名称
  shang_ping_bian_hao: 款式编号
  chi_ma: 尺码
  yang_se: 颜色
  dan_wei: 单位
  xia_dan_zong_shu: 下单总数
  bei_zhu: 备注
  yan_se: 颜色
  tu_pian: 图片
  gong_yi_lu_xiang: 工序表
  gong_yi_yao_qiu: 工艺要求
  chi_cun_biao: 尺寸表
  zong_jia: 总价
  gong_xu_ming_cheng: 工序名称
  ji_jian_dan_jia: 计件单价
  gong_zuo_huan_jie: 工作环节
  zi_you_ji_shu: 自由记数
  gong_kai_dan_jia: 公开单价
  zhi_pai_bu_men: 指派部门
  bu_wei_chi_cun: 部位/尺寸
  bu_wei: 部位

#一些提示和描述
tip:
  yan_se_tu_pian: 点击图片框下面空白处，支持复制粘贴上传图片  

#提示文字
placeholder:
  xuan_ze_gong_zuo_huan_jie: 选择工作环节
  xuan_ze_bu_men: 选择部门
  hui_che_sou_suo: 回车搜索
  
#标题
title:
  bian_ji_dang_cha: 编辑尺寸档差
  
#款式管理
kuan_shi_guan_li:
  shang_pin_guan_li: 款式管理  
  xin_jian_ding_dan: 创建订单
  tian_jian_shang_ping: 添加款式
  guan_li_gong_zuo_huang_jie: 管理工作环节
  kuai_su_tian_jia_gong_xu: 快速添加工序
  bian_ji_gong_yi_yao_qiu: 编辑工艺要求
  bao_cun_gong_yi_yao_qiu: 保存工艺要求
  qu_xiao_bian_ji: 取消编辑
  cha_ru_yi_hang: 插入一行
  cha_ru_yi_lie: 插入一列
  she_zhi_dang_cha: 设置档差
  xuan_ze_bu_wei: 选择部位
  dang_cha: 档差  

#订单管理

#按钮文字
buttons:
  que_ding: 确定
  qu_xiao: 取消
  sou_suo: 搜索
  xiu_gai: 修改
  geng_duo: 更多
  bao_cun: 保存
  tian_jia: 添加  
  shan_chu: 删除
  edit: 编辑
  
  hsLoginOut: LoginOut
  hsfullscreen: FullScreen
  hsexitfullscreen: ExitFullscreen
  hsrefreshRoute: RefreshRoute
  hslogin: Login
  hsadd: Add
  hsmark: Mark/Cancel
  hssave: Save
  hssearch: Search
  hsexpendAll: Expand All
  hscollapseAll: Collapse All
  hssystemSet: Open ProjectConfig
  hsdelete: Delete
  hsreload: Reload
  hscloseCurrentTab: Close CurrentTab
  hscloseLeftTabs: Close LeftTabs
  hscloseRightTabs: Close RightTabs
  hscloseOtherTabs: Close OtherTabs
  hscloseAllTabs: Close AllTabs
  hswholeFullScreen: FullScreen
  hswholeExitFullScreen: ExitFull
  hscontentFullScreen: Content FullScreen
  hscontentExitFullScreen: Content ExitFullScreen
menus:
  hshome: Home
  hsorder: Order Management
  hslogin: Login
  hsabnormal: Abnormal Page
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hsFive: "500"
  permission: Permission Manage
  permissionPage: Page Permission
  permissionButton: Button Permission
status:
  hsLoad: Loading...
login:
  username: Username
  password: Password
  login: Login
  usernameReg: Please enter username
  passwordReg: Please enter password
  passwordRuleReg: The password format should be any combination of 8-18 digits
